<template>
  <b-modal id="modal-detail-fixed" hide-header hide-footer size="xl">
    <div v-if="loadData" class="page-static">
      <div class="ct-home" v-if="detailFixed.type != 9">
        <div
          :style="{
            'background-image': returnImg(
              `${detailFixed && detailFixed.article_background_image}`
            ),
            'background-color': `${
              detailFixed && detailFixed.article_background_color
            }`,
            'background-repeat': returnRepeat(
              `${detailFixed && detailFixed.article_background_repeat}`
            ),
            'background-attachment': returnFixed(
              `${detailFixed && detailFixed.article_background_fixed}`
            ),
            'background-position': returnPlace(
              `${detailFixed && detailFixed.article_background_position}`
            ),
            '-webkit-box-shadow': returnShadow(
              `${detailFixed && detailFixed.shadow_color}`
            ),
          }"
        >
          <v-style>
            {{ detailFixed.css }}
          </v-style>
          <div class="page-preview ct-home">
            <div class="container page-content position-relative">
              <div class="card-content pt-3 row accordion-page">
                <div
                  class="ql-editor previewspage"
                  v-for="(value, key) in detailFixed.description"
                  style="width: 100%"
                  :key="key"
                >
                  <b-card-body class="px-0">
                    <div
                      class="w-100"
                      v-for="(itemBody, indexBody) in value.description.blocks"
                      :key="indexBody"
                      :class="itemBody.type === 'toggle' ? 'mt-2' : ''"
                    >
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'paragraph'"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                        :style="{
                          lineHeight:
                            itemBody.data.lineHeight &&
                            itemBody.data.lineHeight !== null
                              ? itemBody.data.lineHeight
                              : '',
                        }"
                      >
                        <div
                          v-if="itemBody.data.text"
                          v-html="itemBody.data.text"
                        ></div>
                        <div
                          v-else
                          v-html="itemBody.data.text"
                          style="height: 1.6em"
                        ></div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'tableOfContents'"
                      >
                        <h3>目次</h3>
                        <ul class="list-unstyled pl-4 listTableOfContent">
                          <li
                            v-for="(itemTOC, index) in itemBody.data.items"
                            :key="index"
                            class="pl-3"
                          >
                            <a
                              style="cursor: pointer"
                              v-scroll-to="{
                                el: '#' + itemTOC.href,
                                offset: -20,
                              }"
                              v-html="itemTOC.text"
                            ></a>
                          </li>
                        </ul>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'header'"
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <h1
                          v-if="itemBody.data.level === 1"
                          class="mb-0"
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody, value.description.blocks)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                          v-html="itemBody.data.text"
                        ></h1>
                        <h2
                          v-if="itemBody.data.level === 2"
                          class="mb-0"
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody, value.description.blocks)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                          v-html="itemBody.data.text"
                        ></h2>
                        <h3
                          v-if="itemBody.data.level === 3"
                          class="mb-0"
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody, value.description.blocks)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                          v-html="itemBody.data.text"
                        ></h3>
                        <h4
                          v-if="itemBody.data.level === 4"
                          class="mb-0"
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody, value.description.blocks)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                          v-html="itemBody.data.text"
                        ></h4>
                        <h5
                          v-if="itemBody.data.level === 5"
                          class="mb-0"
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody, value.description.blocks)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                          v-html="itemBody.data.text"
                        ></h5>
                        <h6
                          v-if="itemBody.data.level === 6"
                          class="mb-0"
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody, value.description.blocks)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                          v-html="itemBody.data.text"
                        ></h6>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'list'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <ul class="w-100" style="list-style-type: decimal">
                          <li
                            v-for="(itemList, indexList) in itemBody.data.items"
                            :key="indexList"
                            class="mb-2"
                            v-html="itemList"
                          ></li>
                        </ul>
                      </div>
                      <div
                        class="item-data w-100"
                        v-if="itemBody.type === 'toggle'"
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <div
                          class="w-100 d-flex align-items-center toggle-block__selector"
                          :id="itemBody.id"
                          :class="
                            itemBody.data.status === 'open'
                              ? 'toggle-open'
                              : 'toggle-close'
                          "
                        >
                          <span
                            class="icon-toggle-panel mr-2"
                            @click="toggleBlock(itemBody)"
                          >
                            <b-icon
                              :icon="
                                itemBody.data.status === 'open'
                                  ? 'x-lg'
                                  : 'plus-lg'
                              "
                            ></b-icon>
                          </span>
                          {{ itemBody.data.text }}
                        </div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'code'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <div v-html="itemBody.data.code"></div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100 my-3 raw-input"
                        v-if="itemBody.type === 'raw'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : ''
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <div v-html="itemBody.data.html"></div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'table'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th
                                v-for="(itemTblTh, indexTblTh) in itemBody.data
                                  .content[0]"
                                :key="indexTblTh"
                                class="border-bottom-0"
                              >
                                <span class="w-100" v-html="itemTblTh"></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(itemTblTr, indexTblTr) in itemBody.data
                                .contentTbody"
                              :key="indexTblTr"
                            >
                              <td
                                v-for="(itemTblTd, indexTblTd) in itemTblTr"
                                :key="indexTblTd"
                              >
                                <span class="w-100" v-html="itemTblTd"></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'checklist'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <div
                          class="custom-control custom-checkbox"
                          v-for="(itemCheckbox, indexCheckbox) in itemBody.data
                            .items"
                          :key="indexCheckbox"
                        >
                          <input
                            :id="'checkbox-' + itemBody.id + indexCheckbox"
                            type="checkbox"
                            :name="'checkbox-' + itemBody.id + indexCheckbox"
                            class="custom-control-input"
                            :checked="itemCheckbox.checked"
                          />
                          <label
                            :for="'checkbox-' + itemBody.id + indexCheckbox"
                            class="custom-control-label ml-1"
                          >
                            <span v-html="itemCheckbox.text"></span>
                          </label>
                        </div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'warning'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <div class="w-100 bg-warning p-3 text-white">
                          <div class="w-100 font-weight-bold">
                            {{ itemBody.data.title }}
                          </div>
                          <hr />
                          <div class="w-100">
                            {{ itemBody.data.message }}
                          </div>
                        </div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'quote'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <blockquote
                          class="otro-blockquote"
                          :class="
                            itemBody.data.alignment === 'left'
                              ? 'text-left'
                              : 'text-right'
                          "
                        >
                          <span>
                            {{ itemBody.data.caption }}
                          </span>
                          <span v-html="itemBody.data.text"></span>
                        </blockquote>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'headerStyle'"
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                      >
                        <div class="titleStyle" :class="itemBody.data.class">
                          <h1 class="dataInput">
                            {{ itemBody.data.text }}
                          </h1>
                        </div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'boxStylle'"
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                      >
                        <div class="boxStyle" :class="itemBody.data.class">
                          <span
                            class="box-title px-2 border-0"
                            v-if="itemBody.data.title"
                            >{{ itemBody.data.title }}</span
                          >
                          <div
                            class="border-0 shadow-none bg-transparent h-auto form-control p-2 dataInput"
                            v-html="itemBody.data.text"
                          ></div>
                        </div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'delimiter'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <div class="w-100 text-center ce-delimiter"></div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'image'"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <a
                          v-if="replaceText(itemBody.data.linkImage)"
                          :href="itemBody.data.linkImage"
                        >
                          <img
                            :src="itemBody.data.file.url"
                            alt=""
                            width="auto"
                          />
                        </a>
                        <img
                          v-else
                          :src="itemBody.data.file.url"
                          alt=""
                          width="auto"
                        />
                        <h4 class="mt-2">{{ itemBody.data.caption }}</h4>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'attachesPDF'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <b-button
                          variant="primary"
                          class="btn-preview-fixed mr-0"
                          name="btn-view-PDF"
                          v-on:click.prevent="
                            viewFilePDF(itemBody.data.file, itemBody.id)
                          "
                        >
                          {{ itemBody.data.title }}
                        </b-button>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        v-if="
                          itemBody.type === 'embed' &&
                          itemBody.data.service === 'youtube'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <YoutubePlayer
                          v-bind:idElemYoutube="itemBody.id"
                          v-bind:idLastYoutube="idLastEmbed"
                          v-bind:timeWatching="timeWatchingEmbed"
                          v-bind:src="itemBody.data.source"
                          v-bind:heightPlayer="itemBody.data.height"
                          v-bind:widthPlayer="itemBody.data.width"
                          v-bind:dataVideo="itemBody.data"
                        />
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        v-if="
                          itemBody.type === 'embed' &&
                          itemBody.data.service === 'vimeo'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <VimeoPlayer
                          v-bind:idElemVimeo="itemBody.id"
                          v-bind:idLastVimeo="idLastEmbed"
                          v-bind:timeWatching="timeWatchingEmbed"
                          v-bind:src="itemBody.data.embed"
                          v-bind:heightPlayer="itemBody.data.height"
                          v-bind:widthPlayer="itemBody.data.width"
                          v-bind:dataVideo="itemBody.data"
                        />
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'anyButton'"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <a
                          v-if="!itemBody.data.type"
                          class="anyButton"
                          :class="itemBody.data.style"
                          @click="downloadFile($event, itemBody.data)"
                        >
                          {{ itemBody.data.text }}
                        </a>
                        <div
                          class="w-100"
                          v-if="
                            itemBody.data.type !== null &&
                            (itemBody.data.style === 'roundButton' ||
                              itemBody.data.style === 'socialGlossySmooth' ||
                              itemBody.data.style === 'socialSquare')
                          "
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody, value.description.blocks)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                        >
                          <a
                            v-if="itemBody.data.twitter !== null"
                            :href="itemBody.data.twitter"
                            class="btn-social-circle btn-social-circle--twitter"
                            ><i class="fab fa-twitter"></i>
                          </a>
                          <a
                            v-if="itemBody.data.facebook !== null"
                            :href="itemBody.data.facebook"
                            class="btn-social-circle btn-social-circle--facebook"
                            ><i class="fab fa-facebook-f"></i>
                          </a>
                          <a
                            v-if="itemBody.data.pocket !== null"
                            :href="itemBody.data.pocket"
                            class="btn-social-circle btn-social-circle--pocket"
                            ><i class="fab fa-get-pocket"></i>
                          </a>
                          <a
                            v-if="itemBody.data.feedly !== null"
                            :href="itemBody.data.feedly"
                            class="btn-social-circle btn-social-circle--feedly"
                            ><i class="fas fa-rss"></i
                          ></a>
                        </div>
                        <div
                          class="w-100"
                          v-if="
                            itemBody.data.type !== null &&
                            (itemBody.data.style === 'socialGiza' ||
                              itemBody.data.style === 'socialSmartPhone')
                          "
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody, value.description.blocks)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                        >
                          <div
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'btn-social-giza'
                                : 'btn-social-phone'
                            "
                          >
                            <a
                              href="1"
                              :class="
                                itemBody.data.style === 'socialGiza'
                                  ? 'btn-social-giza-twitter'
                                  : 'btn-social-phone-twitter ml-0 mr-3'
                              "
                              v-if="itemBody.data.twitter !== null"
                            >
                              <span class="fa-stack">
                                <i
                                  class="fas fa-stack-2x"
                                  :class="
                                    itemBody.data.style === 'socialGiza'
                                      ? 'fa-certificate'
                                      : 'fa-tablet-alt'
                                  "
                                ></i>
                                <i class="fab fa-twitter fa-stack-1x"></i>
                              </span>
                            </a>
                            <a
                              href="3535"
                              :class="
                                itemBody.data.style === 'socialGiza'
                                  ? 'btn-social-giza-facebook'
                                  : 'btn-social-phone-facebook ml-0 mr-3'
                              "
                              v-if="itemBody.data.facebook !== null"
                            >
                              <span class="fa-stack">
                                <i
                                  class="fas fa-stack-2x"
                                  :class="
                                    itemBody.data.style === 'socialGiza'
                                      ? 'fa-certificate'
                                      : 'fa-tablet-alt'
                                  "
                                ></i>
                                <i class="fab fa-facebook-f fa-stack-1x"></i>
                              </span>
                            </a>
                            <a
                              href="5467"
                              :class="
                                itemBody.data.style === 'socialGiza'
                                  ? 'btn-social-giza-pocket'
                                  : 'btn-social-phone-pocket ml-0 mr-3'
                              "
                              v-if="itemBody.data.pocket !== null"
                            >
                              <span class="fa-stack">
                                <i
                                  class="fas fa-stack-2x"
                                  :class="
                                    itemBody.data.style === 'socialGiza'
                                      ? 'fa-certificate'
                                      : 'fa-tablet-alt'
                                  "
                                ></i>
                                <i class="fab fa-get-pocket fa-stack-1x"></i>
                              </span>
                            </a>
                            <a
                              href="1"
                              :class="
                                itemBody.data.style === 'socialGiza'
                                  ? 'btn-social-giza-feedly'
                                  : 'btn-social-phone-feedly ml-0 mr-3'
                              "
                              v-if="itemBody.data.feedly !== null"
                            >
                              <span class="fa-stack">
                                <i
                                  class="fas fa-stack-2x"
                                  :class="
                                    itemBody.data.style === 'socialGiza'
                                      ? 'fa-certificate'
                                      : 'fa-tablet-alt'
                                  "
                                ></i>
                                <i class="fas fa-rss fa-stack-1x"></i>
                              </span>
                            </a>
                          </div>
                        </div>
                        <div
                          class="w-100"
                          v-if="
                            itemBody.data.type !== null &&
                            (itemBody.data.style === 'socialFlat' ||
                              itemBody.data.style === 'socialIsometric')
                          "
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                        >
                          <div>
                            <a
                              href="1"
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat'
                                  : 'btn-social-isometric'
                              "
                            >
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-icon btn-social-isometric-icon--twitter'
                                    : 'btn-social-isometric-icon btn-social-isometric-icon--twitter'
                                "
                              >
                                <i class="fab fa-twitter"></i>
                              </span>
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-text'
                                    : 'btn-social-isometric-text'
                                "
                                >TWEET</span
                              >
                            </a>
                          </div>
                          <div>
                            <a
                              href="1"
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat'
                                  : 'btn-social-isometric'
                              "
                            >
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-icon btn-social-isometric-icon--facebook'
                                    : 'btn-social-isometric-icon btn-social-isometric-icon--facebook'
                                "
                              >
                                <i class="fab fa-facebook"></i>
                              </span>
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-text'
                                    : 'btn-social-isometric-text'
                                "
                                >TWEET</span
                              >
                            </a>
                          </div>
                          <div>
                            <a
                              href="1"
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat'
                                  : 'btn-social-isometric'
                              "
                            >
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-icon btn-social-isometric-icon--pocket'
                                    : 'btn-social-isometric-icon btn-social-isometric-icon--pocket'
                                "
                              >
                                <i class="fab fa-get-pocket"></i>
                              </span>
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-text'
                                    : 'btn-social-isometric-text'
                                "
                                >TWEET</span
                              >
                            </a>
                          </div>
                          <div>
                            <a
                              href="1"
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat'
                                  : 'btn-social-isometric'
                              "
                            >
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-icon btn-social-isometric-icon--feedly'
                                    : 'btn-social-isometric-icon btn-social-isometric-icon--feedly'
                                "
                              >
                                <i class="fas fa-rss"></i>
                              </span>
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-text'
                                    : 'btn-social-isometric-text'
                                "
                                >TWEET</span
                              >
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100 my-3"
                        v-if="itemBody.type === 'attachesFile'"
                      >
                        <div class="w-100 d-flex box-file-upload">
                          <div
                            class="iconFile"
                            :data-extension="itemBody.data.file.extension"
                            :style="{ color: itemBody.data.file.color }"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="40"
                            >
                              <path
                                d="M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z"
                              />
                            </svg>
                          </div>
                          <div class="fileName">
                            <div class="w-100">
                              {{ itemBody.data.file.name }}
                            </div>
                            <div
                              class="text-muted w-100"
                              v-html="convertSize(itemBody.data.file.size)"
                            ></div>
                          </div>
                          <div class="iconDowload ml-auto">
                            <a
                              class="iconDowload"
                              v-bind:download="itemBody.data.file.url"
                              rel="nofollow noindex noreferrer"
                              @click="
                                downloadFileUpload($event, itemBody.data.file)
                              "
                            >
                              <i class="fas fa-cloud-download-alt"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        :style="{
          'background-image': returnImg(
            `${detailFixed && detailFixed.article_background_image}`
          ),
          'background-color': `${
            detailFixed && detailFixed.article_background_color
          }`,
          'background-repeat': returnRepeat(
            `${detailFixed && detailFixed.article_background_repeat}`
          ),
          'background-attachment': returnFixed(
            `${detailFixed && detailFixed.article_background_fixed}`
          ),
          'background-position': returnPlace(
            `${detailFixed && detailFixed.article_background_position}`
          ),
          '-webkit-box-shadow': returnShadow(
            `${detailFixed && detailFixed.shadow_color}`
          ),
        }"
        class="fixed-question"
      >
        <v-style>
          {{ detailFixed.css }}
        </v-style>
        <div class="head">
          <b-card-body style="padding: 0 !important">
            <div
              class="w-100"
              v-for="(itemBody, indexBody) in pageAnswer[positionPage].content
                .blocks"
              :key="indexBody"
              :class="itemBody.type === 'toggle' ? 'mt-2' : ''"
            >
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'paragraph'"
                :class="
                  itemBody.tunes && itemBody.idChild
                    ? itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'toggle-block__item text-center'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'toggle-block__item text-right'
                      : checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'center'
                    ? 'text-center my-3'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'right'
                    ? 'text-right my-3'
                    : ' text-left my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
                :style="{
                  lineHeight:
                    itemBody.data.lineHeight &&
                    itemBody.data.lineHeight !== null
                      ? itemBody.data.lineHeight
                      : '',
                }"
              >
                <div
                  v-if="itemBody.data.text"
                  v-html="itemBody.data.text"
                ></div>
                <div
                  v-else
                  v-html="itemBody.data.text"
                  style="height: 1.6em"
                ></div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'tableOfContents'"
              >
                <h3>目次</h3>
                <ul class="list-unstyled pl-4 listTableOfContent">
                  <li
                    v-for="(itemTOC, index) in itemBody.data.items"
                    :key="index"
                    class="pl-3"
                  >
                    <a
                      style="cursor: pointer"
                      v-scroll-to="{
                        el: '#' + itemTOC.href,
                        offset: -20,
                      }"
                      v-html="itemTOC.text"
                    ></a>
                  </li>
                </ul>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'header'"
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <h1
                  v-if="itemBody.data.level === 1"
                  class="mb-0"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  v-html="itemBody.data.text"
                ></h1>
                <h2
                  v-if="itemBody.data.level === 2"
                  class="mb-0"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  v-html="itemBody.data.text"
                ></h2>
                <h3
                  v-if="itemBody.data.level === 3"
                  class="mb-0"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  v-html="itemBody.data.text"
                ></h3>
                <h4
                  v-if="itemBody.data.level === 4"
                  class="mb-0"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  v-html="itemBody.data.text"
                ></h4>
                <h5
                  v-if="itemBody.data.level === 5"
                  class="mb-0"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  v-html="itemBody.data.text"
                ></h5>
                <h6
                  v-if="itemBody.data.level === 6"
                  class="mb-0"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  v-html="itemBody.data.text"
                ></h6>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'list'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <ul class="w-100" style="list-style-type: decimal">
                  <li
                    v-for="(itemList, indexList) in itemBody.data.items"
                    :key="indexList"
                    class="mb-2"
                    v-html="itemList"
                  ></li>
                </ul>
              </div>
              <div
                class="item-data w-100"
                v-if="itemBody.type === 'toggle'"
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <div
                  class="w-100 d-flex align-items-center toggle-block__selector"
                  :id="itemBody.id"
                  :class="
                    itemBody.data.status === 'open'
                      ? 'toggle-open'
                      : 'toggle-close'
                  "
                >
                  <span
                    class="icon-toggle-panel mr-2"
                    @click="toggleBlock(itemBody)"
                  >
                    <b-icon
                      :icon="
                        itemBody.data.status === 'open' ? 'x-lg' : 'plus-lg'
                      "
                    ></b-icon>
                  </span>
                  {{ itemBody.data.text }}
                </div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'code'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <div v-html="itemBody.data.code"></div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100 my-3"
                v-if="itemBody.type === 'raw'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : ''
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <div v-html="itemBody.data.html"></div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'table'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th
                        v-for="(itemTblTh, indexTblTh) in itemBody.data
                          .content[0]"
                        :key="indexTblTh"
                        class="border-bottom-0"
                      >
                        <span class="w-100" v-html="itemTblTh"></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(itemTblTr, indexTblTr) in itemBody.data
                        .contentTbody"
                      :key="indexTblTr"
                    >
                      <td
                        v-for="(itemTblTd, indexTblTd) in itemTblTr"
                        :key="indexTblTd"
                      >
                        <span class="w-100" v-html="itemTblTd"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'checklist'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <div
                  class="custom-control custom-checkbox"
                  v-for="(itemCheckbox, indexCheckbox) in itemBody.data.items"
                  :key="indexCheckbox"
                >
                  <input
                    :id="'checkbox-' + itemBody.id + indexCheckbox"
                    type="checkbox"
                    :name="'checkbox-' + itemBody.id + indexCheckbox"
                    class="custom-control-input"
                    :checked="itemCheckbox.checked"
                  />
                  <label
                    :for="'checkbox-' + itemBody.id + indexCheckbox"
                    class="custom-control-label ml-1"
                  >
                    <span v-html="itemCheckbox.text"></span>
                  </label>
                </div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'warning'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <div class="w-100 bg-warning p-3 text-white">
                  <div class="w-100 font-weight-bold">
                    {{ itemBody.data.title }}
                  </div>
                  <hr />
                  <div class="w-100">
                    {{ itemBody.data.message }}
                  </div>
                </div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'quote'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <blockquote
                  class="otro-blockquote"
                  :class="
                    itemBody.data.alignment === 'left'
                      ? 'text-left'
                      : 'text-right'
                  "
                >
                  <span>
                    {{ itemBody.data.caption }}
                  </span>
                  <span v-html="itemBody.data.text"></span>
                </blockquote>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'headerStyle'"
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
                :class="
                  itemBody.tunes && itemBody.idChild
                    ? itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'toggle-block__item text-center'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'toggle-block__item text-right'
                      : checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'center'
                    ? 'text-center my-3'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'right'
                    ? 'text-right my-3'
                    : ' text-left my-3'
                "
              >
                <div class="titleStyle" :class="itemBody.data.class">
                  <h1 class="dataInput">
                    {{ itemBody.data.text }}
                  </h1>
                </div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'boxStylle'"
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
                :class="
                  itemBody.tunes && itemBody.idChild
                    ? itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'toggle-block__item text-center'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'toggle-block__item text-right'
                      : checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'center'
                    ? 'text-center my-3'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'right'
                    ? 'text-right my-3'
                    : ' text-left my-3'
                "
              >
                <div class="boxStyle" :class="itemBody.data.class">
                  <span
                    class="box-title px-2 border-0"
                    v-if="itemBody.data.title"
                    >{{ itemBody.data.title }}</span
                  >
                  <div
                    class="border-0 shadow-none bg-transparent h-auto form-control p-2 dataInput"
                    v-html="itemBody.data.text"
                  ></div>
                </div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'delimiter'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <div class="w-100 text-center ce-delimiter"></div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'image'"
                :class="
                  itemBody.tunes && itemBody.idChild
                    ? itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'toggle-block__item text-center'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'toggle-block__item text-right'
                      : checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'center'
                    ? 'text-center my-3'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'right'
                    ? 'text-right my-3'
                    : ' text-left my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <a
                  v-if="replaceText(itemBody.data.linkImage)"
                  :href="itemBody.data.linkImage"
                >
                  <img :src="itemBody.data.file.url" width="auto" />
                </a>
                <img v-else :src="itemBody.data.file.url" alt="" width="auto" />
                <h4 class="mt-2">{{ itemBody.data.caption }}</h4>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'attachesPDF'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <b-button
                  variant="primary"
                  class="btn-preview-fixed mr-0"
                  name="btn-view-PDF"
                  v-on:click.prevent="viewFilePDF(itemBody.data.file)"
                >
                  {{ itemBody.data.title }}
                </b-button>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                v-if="
                  itemBody.type === 'embed' &&
                  itemBody.data.service === 'youtube'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <YoutubePlayer
                  v-bind:idElemYoutube="itemBody.id"
                  v-bind:idLastYoutube="idLastEmbed"
                  v-bind:timeWatching="timeWatchingEmbed"
                  v-bind:src="itemBody.data.source"
                  v-bind:heightPlayer="itemBody.data.height"
                  v-bind:widthPlayer="itemBody.data.width"
                  v-bind:dataVideo="itemBody.data"
                />
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                v-if="
                  itemBody.type === 'embed' && itemBody.data.service === 'vimeo'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <VimeoPlayer
                  v-bind:idElemVimeo="itemBody.id"
                  v-bind:idLastVimeo="idLastEmbed"
                  v-bind:timeWatching="timeWatchingEmbed"
                  v-bind:src="itemBody.data.embed"
                  v-bind:heightPlayer="itemBody.data.height"
                  v-bind:widthPlayer="itemBody.data.width"
                  v-bind:dataVideo="itemBody.data"
                />
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'anyButton'"
                :class="
                  itemBody.tunes && itemBody.idChild
                    ? itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'toggle-block__item text-center'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'toggle-block__item text-right'
                      : checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'center'
                    ? 'text-center my-3'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'right'
                    ? 'text-right my-3'
                    : ' text-left my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <a
                  v-if="itemBody.data.type === null"
                  class="anyButton"
                  :class="itemBody.data.style"
                  @click="downloadFile($event, itemBody.data)"
                >
                  {{ itemBody.data.text }}
                </a>
                <div
                  class="w-100"
                  v-if="
                    itemBody.data.type !== null &&
                    (itemBody.data.style === 'roundButton' ||
                      itemBody.data.style === 'socialGlossySmooth' ||
                      itemBody.data.style === 'socialSquare')
                  "
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                >
                  <a
                    v-if="itemBody.data.twitter !== null"
                    :href="itemBody.data.twitter"
                    class="btn-social-circle btn-social-circle--twitter"
                    ><i class="fab fa-twitter"></i>
                  </a>
                  <a
                    v-if="itemBody.data.facebook !== null"
                    :href="itemBody.data.facebook"
                    class="btn-social-circle btn-social-circle--facebook"
                    ><i class="fab fa-facebook-f"></i>
                  </a>
                  <a
                    v-if="itemBody.data.pocket !== null"
                    :href="itemBody.data.pocket"
                    class="btn-social-circle btn-social-circle--pocket"
                    ><i class="fab fa-get-pocket"></i>
                  </a>
                  <a
                    v-if="itemBody.data.feedly !== null"
                    :href="itemBody.data.feedly"
                    class="btn-social-circle btn-social-circle--feedly"
                    ><i class="fas fa-rss"></i
                  ></a>
                </div>
                <div
                  class="w-100"
                  v-if="
                    itemBody.data.type !== null &&
                    (itemBody.data.style === 'socialGiza' ||
                      itemBody.data.style === 'socialSmartPhone')
                  "
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                >
                  <div
                    :class="
                      itemBody.data.style === 'socialGiza'
                        ? 'btn-social-giza'
                        : 'btn-social-phone'
                    "
                  >
                    <a
                      href="1"
                      :class="
                        itemBody.data.style === 'socialGiza'
                          ? 'btn-social-giza-twitter'
                          : 'btn-social-phone-twitter ml-0 mr-3'
                      "
                      v-if="itemBody.data.twitter !== null"
                    >
                      <span class="fa-stack">
                        <i
                          class="fas fa-stack-2x"
                          :class="
                            itemBody.data.style === 'socialGiza'
                              ? 'fa-certificate'
                              : 'fa-tablet-alt'
                          "
                        ></i>
                        <i class="fab fa-twitter fa-stack-1x"></i>
                      </span>
                    </a>
                    <a
                      href="3535"
                      :class="
                        itemBody.data.style === 'socialGiza'
                          ? 'btn-social-giza-facebook'
                          : 'btn-social-phone-facebook ml-0 mr-3'
                      "
                      v-if="itemBody.data.facebook !== null"
                    >
                      <span class="fa-stack">
                        <i
                          class="fas fa-stack-2x"
                          :class="
                            itemBody.data.style === 'socialGiza'
                              ? 'fa-certificate'
                              : 'fa-tablet-alt'
                          "
                        ></i>
                        <i class="fab fa-facebook-f fa-stack-1x"></i>
                      </span>
                    </a>
                    <a
                      href="5467"
                      :class="
                        itemBody.data.style === 'socialGiza'
                          ? 'btn-social-giza-pocket'
                          : 'btn-social-phone-pocket ml-0 mr-3'
                      "
                      v-if="itemBody.data.pocket !== null"
                    >
                      <span class="fa-stack">
                        <i
                          class="fas fa-stack-2x"
                          :class="
                            itemBody.data.style === 'socialGiza'
                              ? 'fa-certificate'
                              : 'fa-tablet-alt'
                          "
                        ></i>
                        <i class="fab fa-get-pocket fa-stack-1x"></i>
                      </span>
                    </a>
                    <a
                      href="1"
                      :class="
                        itemBody.data.style === 'socialGiza'
                          ? 'btn-social-giza-feedly'
                          : 'btn-social-phone-feedly ml-0 mr-3'
                      "
                      v-if="itemBody.data.feedly !== null"
                    >
                      <span class="fa-stack">
                        <i
                          class="fas fa-stack-2x"
                          :class="
                            itemBody.data.style === 'socialGiza'
                              ? 'fa-certificate'
                              : 'fa-tablet-alt'
                          "
                        ></i>
                        <i class="fas fa-rss fa-stack-1x"></i>
                      </span>
                    </a>
                  </div>
                </div>
                <div
                  class="w-100"
                  v-if="
                    itemBody.data.type !== null &&
                    (itemBody.data.style === 'socialFlat' ||
                      itemBody.data.style === 'socialIsometric')
                  "
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                >
                  <div>
                    <a
                      href="1"
                      :class="
                        itemBody.data.style === 'socialFlat'
                          ? 'btn-social-flat'
                          : 'btn-social-isometric'
                      "
                    >
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-icon btn-social-isometric-icon--twitter'
                            : 'btn-social-isometric-icon btn-social-isometric-icon--twitter'
                        "
                      >
                        <i class="fab fa-twitter"></i>
                      </span>
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-text'
                            : 'btn-social-isometric-text'
                        "
                        >TWEET</span
                      >
                    </a>
                  </div>
                  <div>
                    <a
                      href="1"
                      :class="
                        itemBody.data.style === 'socialFlat'
                          ? 'btn-social-flat'
                          : 'btn-social-isometric'
                      "
                    >
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-icon btn-social-isometric-icon--facebook'
                            : 'btn-social-isometric-icon btn-social-isometric-icon--facebook'
                        "
                      >
                        <i class="fab fa-facebook"></i>
                      </span>
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-text'
                            : 'btn-social-isometric-text'
                        "
                        >TWEET</span
                      >
                    </a>
                  </div>
                  <div>
                    <a
                      href="1"
                      :class="
                        itemBody.data.style === 'socialFlat'
                          ? 'btn-social-flat'
                          : 'btn-social-isometric'
                      "
                    >
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-icon btn-social-isometric-icon--pocket'
                            : 'btn-social-isometric-icon btn-social-isometric-icon--pocket'
                        "
                      >
                        <i class="fab fa-get-pocket"></i>
                      </span>
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-text'
                            : 'btn-social-isometric-text'
                        "
                        >TWEET</span
                      >
                    </a>
                  </div>
                  <div>
                    <a
                      href="1"
                      :class="
                        itemBody.data.style === 'socialFlat'
                          ? 'btn-social-flat'
                          : 'btn-social-isometric'
                      "
                    >
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-icon btn-social-isometric-icon--feedly'
                            : 'btn-social-isometric-icon btn-social-isometric-icon--feedly'
                        "
                      >
                        <i class="fas fa-rss"></i>
                      </span>
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-text'
                            : 'btn-social-isometric-text'
                        "
                        >TWEET</span
                      >
                    </a>
                  </div>
                </div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100 my-3"
                v-if="itemBody.type === 'attachesFile'"
              >
                <div class="w-100 d-flex box-file-upload">
                  <div
                    class="iconFile"
                    :data-extension="itemBody.data.file.extension"
                    :style="{ color: itemBody.data.file.color }"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="40"
                    >
                      <path
                        d="M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z"
                      />
                    </svg>
                  </div>
                  <div class="fileName">
                    <div class="w-100">
                      {{ itemBody.data.file.name }}
                    </div>
                    <div
                      class="text-muted w-100"
                      v-html="convertSize(itemBody.data.file.size)"
                    ></div>
                  </div>
                  <div class="iconDowload ml-auto">
                    <a
                      class="iconDowload"
                      v-bind:download="itemBody.data.file.url"
                      rel="nofollow noindex noreferrer"
                      @click="downloadFileUpload($event, itemBody.data.file)"
                    >
                      <i class="fas fa-cloud-download-alt"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </b-card-body>
        </div>
        <div
          v-if="
            pageAnswer[positionPage].type != 0 &&
            pageAnswer[positionPage].type != 5
          "
        >
          <hr />
        </div>
        <div class="body">
          <div
            class="answer"
            v-if="
              pageAnswer[positionPage].type != 0 &&
              pageAnswer[positionPage].type != 5
            "
          >
            <div v-if="pageAnswer[positionPage].type == 1">
              <h5>{{ pageAnswer[positionPage].text_type }}</h5>
              <b-form-input
                class="form-control"
                v-model="pageAnswer[positionPage].answer"
              ></b-form-input>
            </div>
            <div v-else-if="pageAnswer[positionPage].type == 2">
              <h5>{{ pageAnswer[positionPage].text_type }}</h5>
              <b-form-textarea
                class="form-control"
                no-resize
                rows="10"
                v-model="pageAnswer[positionPage].answer"
              ></b-form-textarea>
            </div>
            <div v-else-if="pageAnswer[positionPage].type == 3">
              <h5>{{ pageAnswer[positionPage].text_type }}</h5>
              <b-row>
                <b-col
                  cols="6"
                  lg="3"
                  class="mb-3"
                  v-for="(v, i) in pageAnswer[positionPage].radio"
                  :key="i"
                >
                  <label
                    class="position-relative py-2 w-100"
                    @click="
                      selectRadio = v.value;
                      selectRadioText = v.text;
                      selectRadioId = v.id;
                    "
                    :class="
                      selectRadioId == v.id
                        ? 'btn-share-freesize-active py-2 px-4'
                        : 'btn-share-freesize py-2 px-4'
                    "
                    style="font-weight: normal !important; font-size: 17px"
                  >
                    {{ v.text }}
                  </label>
                </b-col>
              </b-row>
            </div>
            <div v-else-if="pageAnswer[positionPage].type == 4">
              <h5>{{ pageAnswer[positionPage].text_type }}</h5>
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  id="checkbox-group-2"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-2"
                >
                  <b-row>
                    <b-col
                      cols="6"
                      lg="3"
                      class="mb-3"
                      v-for="(v, i) in pageAnswer[positionPage].checkbox"
                      :key="i"
                    >
                      <label
                        class="position-relative py-2 w-100"
                        :class="
                          pageAnswer[positionPage].answer &&
                          pageAnswer[positionPage].answer.some(function (
                            field
                          ) {
                            return field === v.value;
                          })
                            ? 'btn-share-freesize-active py-2 px-4'
                            : 'btn-share-freesize py-2 px-4'
                        "
                        style="
                          font-weight: normal !important;
                          font-size: 17px;
                          min-height: 41.5px;
                        "
                      >
                        <b-form-checkbox
                          v-model="pageAnswer[positionPage].answer"
                          :value="v.value"
                          class="d-none"
                        ></b-form-checkbox>
                        {{ v.text }}
                      </label>
                    </b-col>
                  </b-row>
                </b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="navigation">
          <div
            v-if="endFixed == true"
            class="d-flex justify-content-center w-100"
            style="height: 35px; align-self: flex-end"
          >
            <button
              type="button"
              class="btn btn-secondary"
              style="min-width: 150px"
              v-on:click.prevent="closePage()"
            >
              閉じる
            </button>
          </div>
          <div
            v-else
            class="d-flex justify-content-between w-100"
            style="height: 35px; align-self: flex-end"
          >
            <div>
              <button
                type="button"
                class="btn btn-secondary"
                style="min-width: 150px"
                v-on:click.prevent="prevPage()"
                v-if="positionPage > 0"
              >
                戻る
              </button>
            </div>
            <div>
              <button
                type="button"
                class="btn btn-danger"
                style="
                  min-width: 150px;
                  float: right;
                  font-size: 14px;
                  background-color: #ff644e !important;
                "
                v-on:click.prevent="submitPage()"
                v-if="pageFinal == true"
                :disabled="checkValidate == false"
              >
                回答を送信
              </button>
              <button
                type="button"
                class="btn btn-success"
                style="
                  min-width: 150px;
                  float: right;
                  font-size: 14px !important;
                "
                v-on:click.prevent="nextPage()"
                v-else-if="pageQuestion.length > positionPage"
                :disabled="checkValidate == false"
              >
                次へ
              </button>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        id="modal-detail-pdf"
        hide-footer
        modal-class="modal-fullscreen"
        v-model="modalShow"
      >
        <ViewPdf
          v-bind:src="filePDFView && filePDFView.url"
          v-bind:idComponent="filePDFView && filePDFView.idComponent"
          v-bind:dataInfo="filePDFView"
        />
      </b-modal>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import no_image from "@/assets/img/no-image.jpg";
import { Constants } from "../../utils/constants.js";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Vue from "vue";
import ViewPdf from "../../components/viewPdf/index.vue";
import YoutubePlayer from "../../components/youtubePlayer/index.vue";
import VimeoPlayer from "../../components/vimeoPlayer";
import { eventBus } from "../../main";

Vue.component("v-style", {
  render: function (createElement) {
    return createElement("style", this.$slots.default);
  },
});

function initialState() {
  return {
    urlBackend: Constants.URL_BE + "/",
    no_image: no_image,
    page: null,
    check: true,
    shop_id: localStorage.getItem(Constants.SHOP_ID),
    title: null,
    description: null,
    checked: null,
    loading: document.getElementsByClassName("loading-screen"),
    type_fixed: Constants.FIXED_PAGE_TYPE,
    loadData: false,
    idLastEmbed: null,
    timeWatchingEmbed: 0,
    statusYT: {
      playing: 1,
      paused: 2,
      ended: 0,
    },
    statusClick: {
      start: 0,
      paused: 1,
      end: 2,
    },
    dynamicTime: {},
    dynamicTimeVimeo: {},
    filePDFView: null,
    modalShow: false,
    pageQuestion: [],
    positionPage: 0,
    pageAnswer: [],
    selectRadio: "",
    selectRadioText: "",
    selectRadioId: "",
    pageSub: [],
    paged: 0,
    pageFinal: false,
    endFixed: false,
    checkValidate: false,
  };
}

export default {
  name: "pagePopup",
  components: {
    ViewPdf,
    YoutubePlayer,
    VimeoPlayer,
  },
  data() {
    return initialState();
  },
  computed: {
    ...mapGetters(["detailFixed", "message", "detailCheckSentence"]),
  },
  async mounted() {
    eventBus.$on("loadModalFixed", async (data) => {
      const reqData = {
        id: data,
        shop_id: this.shop_id,
        is_click: localStorage.getItem("isClick") ? false : true,
      };
      await this.getFixedPage(reqData);

      let req = {
        shop_id: this.shop_id,
      };
      await this.checkSentence(req);
    });
  },
  watch: {
    detailFixed() {
      if (this.detailFixed) {
        this.loadData = true;
        const token = localStorage.getItem(Constants.TOKEN_USER)
          ? localStorage.getItem(Constants.TOKEN_USER)
          : "";
        if (token) {
          if (this.detailFixed.type != 9) {
            // add infor of fixed page
            var inputs = localStorage.getItem(Constants.INFOR_ORDER);
            if (inputs) {
              inputs = JSON.parse(inputs);
              if (Object.keys(this.detailCheckSentence).length > 0) {
                inputs = Object.assign(inputs, this.detailCheckSentence);
              }
              Object.keys(inputs).forEach((key) => {
                var value = inputs[key];
                const regex = new RegExp("{%" + key + "%}", "g"); // add this regex
                this.detailFixed.description.forEach((fp) => {
                  // eslint-disable-next-line no-unused-vars
                  fp.description.blocks.forEach((item) => {
                    if (item.data && item.data.text)
                      item.data.text = item.data.text.replace(regex, value);

                    if (
                      item.data &&
                      item.data.content &&
                      item.data.content.length > 0
                    ) {
                      item.data.content.forEach((itemContent) => {
                        if (itemContent.length > 0) {
                          itemContent.forEach((it, index) => {
                            itemContent[index] = it.replace(regex, value);
                          });
                        }
                      });
                    }
                  });
                });
              });
            }

            var inforPast =
              localStorage.getItem(Constants.NORMAL_USER_INFO) &&
              JSON.parse(localStorage.getItem(Constants.NORMAL_USER_INFO));
            inforPast["name"] =
              inforPast["last_name"] + inforPast["first_name"];
            if (Object.keys(this.detailCheckSentence).length > 0) {
              inforPast = Object.assign(inforPast, this.detailCheckSentence);
            }
            Object.keys(inforPast).forEach((key) => {
              var value = inforPast[key];
              const regex = new RegExp("{%" + key + "%}", "g"); // add this regex
              this.detailFixed.description.forEach((fp) => {
                // eslint-disable-next-line no-unused-vars
                fp.description.blocks.forEach((item) => {
                  if (item.data && item.data.text)
                    item.data.text = item.data.text.replace(regex, value);

                  if (
                    item.data &&
                    item.data.content &&
                    item.data.content.length > 0
                  ) {
                    item.data.content.forEach((itemContent) => {
                      if (itemContent.length > 0) {
                        itemContent.forEach((it, index) => {
                          itemContent[index] = it.replace(regex, value);
                        });
                      }
                    });
                  }
                });
              });
            });

            this.page = this.detailFixed;
            let externalScript = document.createElement("script");
            externalScript.innerHTML = this.detailFixed.js;
            document.head.appendChild(externalScript);
            this.detailFixed.description.map((item) => {
              const blocksData = item.description.blocks;
              blocksData.map((itemBlock) => {
                if (itemBlock.type === "attachesPDF") {
                  itemBlock.data.file["view"] = null;
                  itemBlock.data.file["idComponent"] = item.id;
                }
                if (itemBlock.type === "table") {
                  itemBlock.data.contentTbody = itemBlock.data.content.filter(
                    (itemBlock, index) => index > 0
                  );
                  return itemBlock;
                }
                if (itemBlock.type === "toggle") {
                  itemBlock.data.status = "closed";
                  const indexBlockToggle = blocksData.indexOf(itemBlock);
                  if (itemBlock.data.child.length >= itemBlock.data.items)
                    return;
                  for (let i = 1; i <= itemBlock.data.items; i++) {
                    itemBlock.data.child.push(blocksData[indexBlockToggle + i]);
                    if (itemBlock.data.status === "closed")
                      blocksData[indexBlockToggle + i].showDisplay = true;
                    blocksData[indexBlockToggle + i].idChild = itemBlock.id;
                  }
                }
              });
            });
          }
          else {
            this.positionPage = 0;
            this.paged = 0;
            this.pageAnswer = [];
            this.pageSub = [];
            this.pageFinal = false;
            this.endFixed = false;
            this.selectRadio = "";
            this.selectRadioText = "";
            this.checkValidate = false;
            let pageJson = this.detailFixed.page_json.map((group) => {
              group.page.forEach((item) => {
                item.title_group = group.title;
              });
              return group.page;
            });
            this.pageQuestion = [].concat(...pageJson);
            this.pageQuestion.forEach((page) => {
              if (page.type == 3) {
                page.radio.forEach((item) => {
                  let pageCheck = this.pageQuestion.filter((ques) => {
                    return ques.id === item.value;
                  });
                  if (pageCheck[0] && pageCheck[0].type != 5) {
                    this.pageSub.push(item.value);
                  }
                });
              }
            });
            this.pageAnswer.push(this.pageQuestion[this.positionPage]);
          }
        }
        this.$bvModal.show("modal-detail-fixed");
      }
      this.$store.commit("set", ["idModalFixed", ""]);
      localStorage.removeItem(Constants.ID_MODAL_FIXED);
      localStorage.removeItem(Constants.ID_MODAL_FIXED_LOGIN);
      localStorage.removeItem(Constants.ID_MODAL_FIXED_SIGNUP);
      localStorage.removeItem("isClick");
    },
    pageAnswer: {
      handler: function (value) {
        if (
          value[this.positionPage].type != 5 &&
          value[this.positionPage].type != 0 &&
          (value[this.positionPage].answer == "" ||
            !value[this.positionPage].answer)
        ) {
          this.checkValidate = false;
        } else {
          this.checkValidate = true;
        }
      },
      deep: true,
    },
    selectRadio: {
      handler: function (value) {
        if (value == "") {
          this.checkValidate = false;
        } else {
          this.checkValidate = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      getFixedPage: "getFixedPage",
      checkSentence: "checkSentence",
      storeFixedQuestion: "storeFixedQuestion",
      givePageThank: "givePageThank",
    }),
    async nextPage() {
      this.checkValidate = false;
      if (!this.pageAnswer[this.positionPage + 1]) {
        // check next page empty
        if (this.pageAnswer[this.positionPage].type == 3) {
          if (this.selectRadio != null) {
            this.pageAnswer[this.positionPage].answer = this.selectRadio;
            let page = this.pageQuestion.filter(
              (item) => item.id === this.selectRadio
            );
            this.pageAnswer.push(page[0]);
          } else {
            this.pageAnswer[this.positionPage].answer = this.selectRadioText;
            this.paged++;
            this.checkNextPage(this.paged);
          }
        } else {
          this.paged++;
          this.checkNextPage(this.paged);
        }
      } else {
        if (
          this.pageAnswer[this.positionPage].type == 3 &&
          this.pageAnswer[this.positionPage].answer &&
          this.pageAnswer[this.positionPage].answer != this.selectRadio
        ) {
          if (this.selectRadio != null) {
            // check question radio change answer
            this.pageAnswer[this.positionPage].answer = this.selectRadio;
            let page = this.pageQuestion.filter(
              (item) => item.id === this.selectRadio
            );
            this.pageAnswer = this.pageAnswer.slice(0, this.positionPage + 1);
            this.pageAnswer.push(page[0]);
            this.paged = this.positionPage;
          } else {
            this.pageAnswer[this.positionPage].answer = this.selectRadioText;
            this.pageAnswer = this.pageAnswer.slice(0, this.positionPage + 1);
            this.paged = this.positionPage + 1;
            this.checkNextPage(this.paged);
          }
        } else {
          this.paged++;
        }
      }

      this.positionPage++;

      if (this.pageAnswer[this.positionPage].type == 5) {
        await this.giveContentSlagPageThank(this.pageAnswer[this.positionPage]);
      }

      // check is final page filter by question radio
      let checkFinalPage = this.checkFinalPage(this.paged + 1);
      if (checkFinalPage == true) {
        this.pageFinal = true;
        this.$forceUpdate();
      }
      if (this.pageAnswer[this.positionPage]) {
        if (
          this.pageAnswer[this.positionPage].type == 3 &&
          this.pageAnswer[this.positionPage].answer
        ) {
          let check = this.pageAnswer[this.positionPage].radio.some((item) => {
            return item.value === this.pageAnswer[this.positionPage].answer;
          });
          if (!check) {
            this.selectRadioText = this.pageAnswer[this.positionPage].answer;
            this.selectRadio = null;
          } else {
            this.selectRadio = this.pageAnswer[this.positionPage].answer;
          }
        } else if (this.pageAnswer[this.positionPage].type == 5) {
          this.endFixed = true;
        }
        if (this.pageAnswer[this.positionPage].answer) {
          this.checkValidate = true;
        }
      } else if (!this.pageAnswer[this.positionPage]) {
        this.closePage();
      }
    },
    checkNextPage(paged) {
      // page + 1
      if (this.pageQuestion[paged] && this.pageQuestion[paged].id) {
        const checkSubPage = this.pageSub.filter(
          (item) => item == this.pageQuestion[paged].id
        );
        if (checkSubPage.length > 0) {
          return this.checkNextPage(paged + 1);
        } else {
          return this.pageAnswer.push(this.pageQuestion[paged]);
        }
      }
    },
    checkFinalPage(paged) {
      if (
        this.pageQuestion[paged] &&
        this.pageQuestion[paged].id &&
        this.pageQuestion[this.pageQuestion.length] !==
          this.pageQuestion[paged] &&
        this.pageQuestion[paged].type != 5
      ) {
        const checkSubPage = this.pageSub.filter(
          (item) => item == this.pageQuestion[paged].id
        );
        if (checkSubPage.length > 0) {
          return this.checkFinalPage(paged + 1);
        } else {
          return false;
        }
      }
      return true;
    },
    prevPage() {
      this.paged--;
      this.positionPage--;
      this.pageFinal = false;
      this.checkValidate = false;
      if (
        this.pageAnswer[this.positionPage].type == 3 &&
        this.pageAnswer[this.positionPage].answer
      ) {
        let check = this.pageAnswer[this.positionPage].radio.some((item) => {
          return item.value === this.pageAnswer[this.positionPage].answer;
        });
        if (!check) {
          this.selectRadioText = this.pageAnswer[this.positionPage].answer;
          this.selectRadio = null;
        } else {
          this.selectRadio = this.pageAnswer[this.positionPage].answer;
        }
      }
      if (this.pageAnswer[this.positionPage].answer) {
        this.checkValidate = true;
      }
    },
    async submitPage() {
      const req = {
        shop_id: this.shop_id,
        fixed_page_id: this.detailFixed.id,
        answer: this.pageAnswer,
        question: this.detailFixed.page_json,
      };
      await this.storeFixedQuestion(req);

      this.nextPage();
    },
    closePage() {
      this.$bvModal.hide("modal-detail-fixed");
    },
    async giveContentSlagPageThank(page) {
      const req = {
        shop_id: this.shop_id,
        page: page,
      };
      await this.givePageThank(req);
      if (page.url) {
        window.location.href = page.url;
        return;
      }
    },
    objectFlip(obj) {
      return Object.keys(obj).reduce((ret, key) => {
        ret[obj[key]] = key;
        return ret;
      }, {});
    },
    clickButton(index) {
      this.$root.$emit("bv::toggle::collapse", "accordion" + index);
    },
    toggleBlock(item) {
      const value = item.data.status === "closed";
      const children = document.querySelectorAll(`div[id-child="${item.id}"]`);
      const { length } = children;

      if (length > 0) {
        for (let i = 0; i < length; i++) {
          children[i].hidden = !value;
          if (children[i].showDisplay !== undefined)
            children[i].showDisplay = !value;
        }
      }
      if (item.data.status === "open") {
        item.data.status = "closed";
      } else {
        item.data.status = "open";
      }
    },
    returnRepeat(type) {
      if (type == 0) {
        return "repeat";
      } else if (type == 1) {
        return "repeat-x";
      } else if (type == 2) {
        return "repeat-y";
      } else {
        return "no-repeat";
      }
    },
    returnFixed(type) {
      if (type == 0) {
        return "fixed";
      } else {
        return "scroll";
      }
    },
    returnPlace(type) {
      if (type == 0) {
        return "right";
      } else if (type == 1) {
        return "center";
      } else {
        return "left";
      }
    },
    returnShadow(color) {
      if (this.detailFixed.article_background_shadow == 0) {
        return "5px 5px 15px " + color;
      } else {
        return "none";
      }
    },
    returnImg(url) {
      if (url) {
        return "url(" + this.urlBackend + url + ")";
      } else return "";
    },
    replaceText(str) {
      return str ? str.replaceAll("&nbsp;", "").trim() : str;
    },
    checkIndex(toggle, list) {
      var listToggle = list.filter((item) => item.idChild === toggle.idChild);
      if (listToggle.length == 1) {
        return true;
      } else {
        var indexCurrent = listToggle.findIndex(
          (item) => item.id === toggle.id
        );
        if (indexCurrent == listToggle.length - 1) return true;
        else return false;
      }
    },

    settingPlayerYoutube(id, video_Id) {
      const check = this;
      // eslint-disable-next-line no-undef
      new YT.Player(id, {
        // height: "390",
        // width: "640",
        videoId: video_Id,
        events: {
          onReady: check.onPlayerReady,
          onStateChange: check.onPlayerStateChange,
        },
      });
    },
    onPlayerStateChange(event) {
      let inforVideo = event.target.playerInfo;
      let videoID = inforVideo.videoData.video_id;
      let name = "time_yt_" + videoID;
      let videoTitle = inforVideo.videoData.title;
      let videoURL = inforVideo.videoUrl;
      if (event.data == this.statusYT["playing"]) {
        console.log("Youtube playing");
        let time_start_view = this.updateTime(event.target.getCurrentTime());
        this.dynamicTime[name] = time_start_view;
        this.logDataAction(
          0,
          videoTitle,
          videoURL,
          time_start_view,
          null,
          null
        );
      } else if (event.data == this.statusYT["paused"]) {
        console.log("Youtube paused");
        let time_end_view = this.updateTime(event.target.getCurrentTime());
        let time_view =
          this.getSecond(time_end_view) -
          this.getSecond(this.dynamicTime[name]);
        this.logDataAction(
          1,
          videoTitle,
          videoURL,
          null,
          time_end_view,
          time_view
        );
      } else if (event.data == this.statusYT["ended"]) {
        console.log("Youtube ended");
        let time_end_view = this.updateTime(event.target.getCurrentTime());
        let time_view =
          this.getSecond(time_end_view) -
          this.getSecond(this.dynamicTime[name]);
        this.logDataAction(
          2,
          videoTitle,
          videoURL,
          null,
          time_end_view,
          time_view
        );
      }
    },
    updateTime(time) {
      time = Math.round(time);
      let hours = Math.floor(time / 3600);
      let minutes = Math.floor((time - hours * 3600) / 60);
      let seconds = time - hours * 3600 - minutes * 60;

      hours = hours < 10 ? "0" + hours : hours;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var checkTime = hours + ":" + minutes + ":" + seconds;
      return checkTime;
    },
    getSecond(time) {
      if (time == 0) return 0;
      else {
        const [hours, minutes, seconds] = time.toString().split(":");
        return +hours * 60 * 60 + +minutes * 60 + +seconds;
      }
    },
    logDataAction(
      status,
      videoTitle,
      videoURL,
      time_start_view,
      time_end_view,
      time_view
    ) {
      const req = {
        shop_id: localStorage.getItem(Constants.SHOP_ID),
        title: this.$route.meta.title,
        referrer_url:
          this.refer_url && this.refer_url != "/"
            ? window.location.origin + this.refer_url
            : window.location.href,
        url: window.document.activeElement.href
          ? window.document.activeElement.href
          : window.document.activeElement.baseURI,
        id_button: window.document.activeElement.name,
        video_url: videoURL,
        video_title: videoTitle,
      };
      if (status == this.statusClick["start"]) {
        req.video_time_start_view = time_start_view;
      } else if (status == this.statusClick["paused"]) {
        req.video_time_end_view = time_end_view;
        req.video_time_view = time_view;
      } else if (status == this.statusClick["end"]) {
        req.video_time_end_view = time_end_view;
        req.video_time_view = time_view;
        req.video_end_view = true;
      }
      req.id_fix_page = this.$route.params.id;
      this.$store.dispatch("logActive", req);
    },

    //vimeo
    getVideoIDVimeo(src) {
      const regExp =
        /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/;
      var match = src.match(regExp);
      const result = regExp.exec(src).slice(1);
      if (match) {
        let videoID = match[6];
        if (
          !src.includes("?") &&
          result[4] &&
          !result[4].includes("video") &&
          result[4].includes("/")
        ) {
          videoID = result[4].slice(0, -1);
        }
        return videoID;
      } else {
        console.log("not a vimeo url");
      }
    },

    async eventVimeoData(status, player, vimeoId) {
      let videoTitle = await player.getVideoTitle();
      let videoURL = await player.getVideoUrl();
      let name = "time_vimeo_" + vimeoId;
      let time = await player.getCurrentTime().then((time) => {
        return this.updateTime(time);
      });
      if (status == this.statusClick["start"]) {
        console.log("Vimeo start");
        let time_start_view = time;
        this.dynamicTimeVimeo[name] = time_start_view;
        this.logDataAction(
          0,
          videoTitle,
          videoURL,
          time_start_view,
          null,
          null
        );
      } else if (status == this.statusClick["paused"]) {
        console.log("Vimeo paused");
        let time_end_view = time;
        let time_view =
          this.getSecond(time_end_view) -
          this.getSecond(this.dynamicTimeVimeo[name]);
        let stateVideo = await player.getEnded();
        if (!stateVideo)
          this.logDataAction(
            1,
            videoTitle,
            videoURL,
            null,
            time_end_view,
            time_view
          );
      } else if (status == this.statusClick["end"]) {
        console.log("Vimeo ended");
        let time_end_view = time;
        let time_view =
          this.getSecond(time_end_view) -
          this.getSecond(this.dynamicTimeVimeo[name]);
        this.logDataAction(
          2,
          videoTitle,
          videoURL,
          null,
          time_end_view,
          time_view
        );
      }
    },

    async viewFilePDF(file, idComponent) {
      this.$store.commit("set", ["clickOnPDF", true]);
      if (file.view === null) {
        const req = {
          shop_id: localStorage.getItem(Constants.SHOP_ID),
          url: window.document.activeElement.href
            ? window.document.activeElement.href
            : window.document.activeElement.baseURI,
          pdf_url: file.url,
        };
        const dataReturn = await this.$store.dispatch("countViewPDF", req);
        if (dataReturn.success) {
          file.view = dataReturn.data + 1;
        }
      } else {
        file.view = file.view + 1;
      }
      file.idComponent = idComponent;
      this.filePDFView = file;
      this.modalShow = !this.modalShow;
    },
    convertSize(size) {
      if (size) {
        let sizePrefix;
        let formattedSize;

        if (Math.log10(+size) >= 6) {
          sizePrefix = "MB";
          formattedSize = size / Math.pow(2, 20);
        } else {
          sizePrefix = "KB";
          formattedSize = size / Math.pow(2, 10);
        }

        return formattedSize.toFixed(1) + sizePrefix;
      }
    },
    async downloadFile(event, data) {
      const fileType = [
        "doc",
        "docx",
        "odt",
        "pdf",
        "rtf",
        "tex",
        "txt",
        "pptx",
        "ppt",
        "mp3",
        "mp4",
        "xls",
        "html",
        "htm",
        "png",
        "jpg",
        "jpeg",
        "gif",
        "zip",
        "rar",
        "exe",
        "svg",
        "key",
        "sketch",
        "ai",
        "psd",
        "dmg",
        "json",
        "csv",
      ];
      const url = data.link;
      var filename = url.substring(url.lastIndexOf("/") + 1);
      const typeFile = url.substring(url.lastIndexOf(".") + 1);
      const checkFile = fileType.filter((item) => item === typeFile);
      if (checkFile.length > 0) {
        fetch(url, {
          header: "Access-Control-Allow-Origin: *",
        })
          .then((response) => response.blob())
          .then((blob) => {
            // Use the blob here...
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
          });
      } else {
        if (data.checkbox) {
          window.open(url, "_blank");
          return;
        }
        window.location = url;
      }
    },
    async downloadFileUpload(event, data) {
      const url = data.url;
      var filename = url.substring(url.lastIndexOf("/") + 1);
      fetch(url, {
        header: "Access-Control-Allow-Origin: *",
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Use the blob here...
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
        });
    },
  },
};
</script>
<style lang="scss">
.ql-editor {
  border: unset !important;
}
.ql-editor.previewspage {
  min-height: unset !important;
}
#modal-detail-fixed .modal-body {
  padding: 0px !important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-bottom: 1.5rem;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid;
  border-radius: 0;
  background-color: #fff;
  border-color: #fff;
}
.link-home-page:hover {
  color: #aaa !important;
}
.text-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 22px;
  line-height: 30px;
  margin-top: 0;
}
.card-header {
  background-color: #fff;
  border-color: #fff;
}
.common-title {
  background-color: #fff;
  border: 1px solid black;
  padding: 5px;
}
.custom-button {
  border: 0px;
  background-color: rgb(255, 255, 255);
  overflow-anchor: none;
  border-top: 1px solid #e6e6e6;
}
.custom-button:hover {
  background-color: #e6e6e6 !important;
}
.custom-button:focus {
  border: 0px !important;
  box-shadow: none !important;
}
.page-static {
  .ql-editor {
    img {
      max-width: 100% !important;
    }
  }
}
.content-frame {
  width: 856.3px;
  margin: 0 auto;
  .ql-editor {
    padding: 12px 60px;
    @media (max-width: 450px) {
      padding: 12px 24px;
    }
  }
  @media (max-width: 1023px) {
    width: 100%;
  }
}

.fixed-question {
  position: relative;
  min-height: 550px;
  display: flex;
  flex-direction: column;

  .head {
    padding: 2rem 4.5rem 0;
    min-height: 13vh;
  }

  .body {
    padding: 0 4.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .answer {
      min-height: 26vh;
    }
  }

  .navigation {
    padding: 0 4.5rem 2rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

#modal-detail-fixed {
  padding: 0 !important;
}

@media (max-width: 450px) {
  .modal-dialog.modal-xl {
    margin: 0.6rem !important;
  }

  .fixed-question .head {
    padding: 0 3.5%;
  }

  .fixed-question .body {
    padding: 0 3.5%;
  }

  .fixed-question .navigation {
    padding: 0 3.5% 3%;
  }
}
</style>
